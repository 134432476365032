import * as React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function RowAndColumnSpacing() {
  return (
    <Box sx={{ width: '100%' }}>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
        <div class="course_box">
          <Item>
            <h2>Java Basics</h2>
            <p>Nauč sa programovať v Jave, a najdi si prácu ako Java Junior programátor.</p>
            <br></br>
            <p>Najbližší termín: 21.07.2023</p>
            <a href="#">Prihlás sa</a>
            </Item>
          </div>
        </Grid>
        <Grid item xs={6}>
        <div class="course_box">
          <Item><h2>Java Advanced</h2>
          <p>Ak už v Jave programovať vieš ale chceš prehĺbiť svoje znalosti.</p>
          <br></br>
          <p>Najbližší termín: 28.07.2023</p>
          <a href="#">Prihlás sa</a>
          </Item>
        </div>
        </Grid>
        <Grid item xs={6}>
        <div class="course_box">
          <Item><h2>Spring Boot Basics</h2>
          <p>Základy používania Spring Boot.</p>
          <br></br>
          <p>Najbližší termín: 04.08.2023</p>
          <a href="#">Prihlás sa</a>
          </Item>
        </div>
        </Grid>
        <Grid item xs={6}>
        <div class="course_box">
          <Item>
            <h2>DDD in Microservices with Event Driven Architecture</h2>
            <p>Domain Driven Desing, architektúra microservisov, CQRS s activemq a event driven architecture</p>
            <br></br>
            <p>Najbližší termín: 04.08.2023</p>
            <a href="#">Prihlás sa</a>
          </Item>
        </div>
        </Grid>
      </Grid>
    </Box>
  );
}